import React, { useEffect, useState } from "react";
import {
    Typography,
    Card,
    CardContent,
    Button,
    CircularProgress,
    Paper,
    InputBase,
    IconButton,
    Accordion,
    AccordionSummary,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GetAppIcon from "@material-ui/icons/GetApp";
import settingStyle from "../../styles/DialogStyle/components/settingsPageStyles";
import { makeStyles } from "@material-ui/styles";
import SearchIcon from "@material-ui/icons/Search";
import HumburgerButton from "../shared/HumburgerButton";
import useStore from "../shared/store";
import { api } from "../shared/utilities/api";
import apiConfigs from "../shared/utilities/apiConfigs";

const useStyles = makeStyles({
    searchContainer: {
        display: "flex",
        alignItems: "center",
        padding: "2px 4px",
        borderRadius: 8,
        border: "2px solid #CB4D00",
        marginBottom: "16px",
        width: "100%",
        boxSizing: "border-box",
    },
    input: {
        marginLeft: "8px",
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
});

function extractFileName(url) {
    const parsedUrl = new URL(url);
    const pathSegments = parsedUrl.pathname.split("/");
    let fileName = decodeURIComponent(pathSegments[pathSegments.length - 1]);
    const extensionIndex = fileName.lastIndexOf(".");
    if (extensionIndex !== -1) {
        const baseName = fileName.substring(0, extensionIndex);
        const extension = fileName.substring(extensionIndex);
        if (baseName.endsWith(extension)) {
            fileName = baseName;
        }
    }
    return fileName;
}

const MessagePage = ({ smallScreenView, handleMenuClick }) => {
    const classes = settingStyle();
    const { user } = useStore();
    const [messages, setMessages] = useState([]);
    const [groupedMessages, setGroupedMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [expandedPatients, setExpandedPatients] = useState({});
    const searchClasses = useStyles();

    const getProviderMessages = async () => {
        try {
            const userId = user._id;
            const orgId = user.orgs[0];
            const result = await api(
                apiConfigs.baseOption(
                    "get",
                    `providerMessages/${orgId}_${userId}`
                )
            );
            setMessages(result.data.messages || []);
            let groupedByPatients = {};
            if (result?.data?.messages?.length) {
                result.data.messages.forEach((message) => {
                    if (groupedByPatients[message.patientDemoNo]) {
                        groupedByPatients[message.patientDemoNo].push(message);
                    } else {
                        groupedByPatients[message.patientDemoNo] = [message];
                    }
                });
                setGroupedMessages(groupedByPatients);
            }
        } catch (error) {
            setError("Messages could not be loaded. Please refresh the page.");
        } finally {
            setLoading(false);
        }
    };

    const handleAccordionToggle = (patientHin) => {
        setExpandedPatients((prevState) => ({
            ...prevState,
            [patientHin]: !prevState[patientHin],
        }));
    };

    const handleSearch = () => {
        if (searchQuery.trim() === "") {
            return;
        }
        const filteredMessages = messages.filter(
            (message) =>
                message.patientDemoNo.includes(searchQuery) ||
                new Date(message.date).toLocaleString().includes(searchQuery) ||
                extractFileName(message.url)
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase())
        );
        const filteredMessagesByHIN = filteredMessages.reduce((acc, item) => {
            if (!acc[item.patientDemoNo]) {
                acc[item.patientDemoNo] = [];
            }   
            acc[item.patientDemoNo].push(item);
            return acc;
        }, {});
        setGroupedMessages(filteredMessagesByHIN);
    };

    const handleDownloadClick = async (message) => {
        window.open(message.url, "_blank");

        try {
            const userId = user._id;
            const orgId = user.orgs[0];
            await api(
                apiConfigs.baseOption(
                    "post",
                    `providerMessages/${orgId}_${userId}/${message._id}/mark-as-read`
                )
            );
            getProviderMessages();
        } catch (err) {
            // fail silently
        }
    };

    const hasUnreadMessages = (messages) => {
        return messages.some((message) => !message.read);
    };

    useEffect(() => {
        getProviderMessages();
        const path = window.location.pathname; 
        const match = path.match(/hin=(\d+)/); 
        if (match) {
            setSearchQuery(match[1]); 

        }
    }, []);

    useEffect(() => {
        getProviderMessages();
    }, []);

    useEffect(() => {
        if (user) {
            getProviderMessages();
        }
    }, [user]);

    useEffect(() => {
        handleSearch();
    }, [searchQuery]);

    useEffect(() => {
        if (!loading && searchQuery && messages.length) {
            handleSearch();
        }
    }, [loading, searchQuery, messages]);

    return (
        <>
            <HumburgerButton
                smallScreenView={smallScreenView}
                handleMenuClick={handleMenuClick}
            />

            <Container style={{ padding: "0px" }}>
                <Box display="flex" style={{ marginTop: 32 }}>
                    <Typography className={classes.labelLarge}>
                        Documents delivered{" "}
                    </Typography>
                </Box>

                {messages.length > 0 && (
                    <Paper
                        className={searchClasses.searchContainer}
                        elevation={1}
                    >
                        <InputBase
                            className={searchClasses.input}
                            placeholder="Search by HIN"
                            value={searchQuery}
                            onChange={(e) => {
                                setSearchQuery(e.target.value);
                            }}
                            onKeyDown={(ev) => {
                                if (ev.key === "Enter") {
                                    handleSearch();
                                    ev.preventDefault();
                                }
                            }}
                        />
                        <IconButton
                            className={searchClasses.iconButton}
                            onClick={handleSearch}
                        >
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                )}

                {loading ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        style={{ height: "100px" }}
                    >
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <Typography color="error">{error}</Typography>
                ) : messages.length > 0 ? (
                    <Box display="flex" flexDirection="column" gap={2}>
                        {Object?.keys(groupedMessages)?.map((patientHin) => (
                            <Card key={patientHin} className={classes.card}>
                                <CardContent>
                                    <Accordion
                                        expanded={
                                            expandedPatients[patientHin] ||
                                            hasUnreadMessages(groupedMessages[patientHin])
                                        }
                                        onChange={() => handleAccordionToggle(patientHin)}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography className={classes.hin}>
                                                Health Identification Number:{" "}
                                                {patientHin}
                                            </Typography>
                                        </AccordionSummary>
                                        <div style={{ paddingInlineStart: "12px" }}>
                                            {groupedMessages[patientHin]?.map(
                                                (message) => (
                                                    <div
                                                        key={message._id}
                                                        style={{ marginBottom: 10 }}
                                                    >
                                                        <Typography className={classes.date}>
                                                            Date:{" "}
                                                            {new Date(
                                                                message.date
                                                            ).toLocaleString()}
                                                        </Typography>
                                                        <Typography
                                                            className={classes.fileName}
                                                            style={{
                                                                color: message.read
                                                                    ? ""
                                                                    : "black",
                                                            }}
                                                        >
                                                            File Name:{" "}
                                                            {extractFileName(
                                                                message.url
                                                            )}
                                                        </Typography>
                                                        <Button
                                                            variant="contained"
                                                            className={
                                                                classes.downloadBtn
                                                            }
                                                            onClick={() =>
                                                                handleDownloadClick(
                                                                    message
                                                                )
                                                            }
                                                        >
                                                            Download File
                                                            <IconButton
                                                                size="small"
                                                                sx={{
                                                                    alignSelf:
                                                                        "center",
                                                                }}
                                                            >
                                                                <GetAppIcon />
                                                            </IconButton>
                                                        </Button>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </Accordion>
                                </CardContent>
                            </Card>
                        ))}
                    </Box>
                ) : (
                    <Paper elevation={2}>
                        <Typography
                            className={classes.noMessage}
                            variant="body1"
                        >
                            There are currently no messages available to view.
                            Please check back later.{" "}
                        </Typography>
                    </Paper>
                )}
            </Container>
        </>
    );
};

export default MessagePage;
